import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'

import theme from 'styles/theme'
import Landing from 'container/Landing'

const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Landing />
    </ThemeProvider>
  )
}

export default IndexPage
