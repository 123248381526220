import { createMuiTheme } from '@material-ui/core'
import fonts from 'styles/fonts.css'

const theme = createMuiTheme({
  palette: {
    common: {
      gold: '#f2a900',
      orange: '#f26449',
      magenta: '#c52c7c',
      green: {
        light: '#8fc73e',
        dark: '#00af78',
      },
      blue: {
        light: '#00b8c4',
        dark: '#3065B0',
      },
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#01253B',
      secondary: '#01253B',
      body: '#01253B',
      header: '#01253B',
    },
  },
  typography: {
    fontFamily: ['Lexend'].join(','),
    header: ['Lexend'].join(','),
  },
  overrides: {
    MuiAccordion: {
      root: {
        '&$expanded': {
          marginTop: 0,
          '&:before': {
            opacity: 100,
          },
        },
      },
    },
    MuiLink: {
      root: {
        color: '#3065B0',
      },
      underlineHover: {
        textDecoration: 'underline',
      },
    },
  },
})

export default theme
