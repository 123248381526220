import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Link from '@material-ui/core/Link'

import NNLogo from 'images/NN-logo.svg'
import PDFIcon from 'images/PDF-icon.png'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.body,
  },
  navBar: {
    backgroundColor: theme.palette.common.white,
    color: 'white',
    boxShadow: 'none',
    padding: '1.3rem 1.2rem',
  },
  title: {
    color: theme.palette.text.header,
    margin: '1rem 0',
    fontFamily: theme.typography.header,
    fontWeight: '700',
  },
  subTitle: {
    marginBottom: '1rem',
    fontFamily: theme.typography.header,
    color: theme.palette.text.header,
    fontWeight: '300',
  },
  summary: {
    color: theme.palette.text.header,
    fontWeight: '500',
  },
  mail: {
    marginLeft: '5px',
  },
  moreInfoTitle: {
    fontFamily: theme.typography.header,
    color: theme.palette.text.header,
    marginTop: '2rem',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  learnMore: {
    marginTop: '1rem',
  },
  font16: {
    fontSize: '1rem',
  },
  footer: {
    height: '2rem',
  },
  faqItem: {
    marginBottom: '1rem',
  },
  pdfIcon: {
    marginRight: '.25rem',
  },
  italic: { fontStyle: 'italic' },
}))

const FAQs = classes => [
  {
    summary: (
      <React.Fragment>
        <div>
          I forgot my password. I know my password, but still can't sign in.
        </div>
      </React.Fragment>
    ),
    details: (
      <React.Fragment>
        <div>
          If you have forgotten your password, you can reset it using the
          security info you registered with us.
        </div>
        <div>
          If you tried signing in with an incorrect password too many times, you
          can keep your existing password and we'll unlock your account so you
          can sign in again.
        </div>
        <Link href="https://passwordreset.microsoftonline.com/" target="blank">
          Get back into your account
        </Link>
      </React.Fragment>
    ),
  },
  {
    summary: (
      <React.Fragment>
        <div>
          I know my password and want to change it. I want to update my security
          info.
        </div>
      </React.Fragment>
    ),
    details: (
      <React.Fragment>
        <div>
          You can change your password and update your security info, including
          the contact methods you have registered with us.
        </div>
        <div>
          Sign in with your work email and password to manage your account.
        </div>
        <Link href="https://myaccount.microsoft.com/" target="blank">
          Manage your account
        </Link>
      </React.Fragment>
    ),
  },
  {
    summary: (
      <React.Fragment>
        <div>I am having some other password problem</div>
      </React.Fragment>
    ),
    details: (
      <React.Fragment>
        <div>
          If you have followed the instructions here and you are still having
          trouble signing in, you should contact us. This may occur if you are
          trying to sign in to an application which uses an account that is
          managed differently to your work email account.
        </div>
      </React.Fragment>
    ),
  },
]

const PolicyLinks = [
  {
    link:
      'https://neamig.sharepoint.com/:b:/r/sites/DocumentDirectory/Shared%20Documents/NG-POL-ICT-10%20Information%20Access%20Control.pdf?web=1',
    title: 'Policy: Information Access Control',
  },
  {
    link:
      'https://neamig.sharepoint.com/:b:/r/sites/DocumentDirectory/Shared%20Documents/NG-POL-ICT-08%20Passwords%20-%20General.pdf?csf=1&web=1',
    title: 'Policy: Password - General',
  },
  {
    link:
      'https://neamig.sharepoint.com/:b:/r/sites/DocumentDirectory/Shared%20Documents/NG-PRO-ICT10-01%20Information%20Security%20Identity%20Proofing.pdf?web=1',
    title: 'Procedure: Information Security Identity Proofing',
  },
]

const Landing = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [expandedIndex, setExpandedIndex] = useState(-1)
  const handleChange = panelIndex => () => {
    if (expandedIndex !== panelIndex) {
      setExpandedIndex(panelIndex)
      return
    }
    setExpandedIndex(-1)
  }

  return (
    <Container disableGutters={true} maxWidth="lg" className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Password FAQ | Neaminational</title>
      </Helmet>

      <AppBar className={classes.navBar} position="static">
        <img src={NNLogo} alt="Neaminational" style={{ width: '10rem' }} />
      </AppBar>
      <Container maxWidth="lg">
        <div>
          <Typography variant="h4" gutterBottom className={classes.title}>
            Neami Password Assistance
          </Typography>
        </div>

        <div>
          <Typography variant="h6" gutterBottom className={classes.subTitle}>
            Having trouble signing in to your Neami login account? You can get
            back into your account by choosing the most likely problem you are
            having below and then following the instructions given. We may need
            to use the contact methods you have registered with us, so it's
            important to keep your security info up to date.
          </Typography>
        </div>
        {false &&
          FAQs(classes).map((faq, index) => (
            <Accordion
              key={faq.summary}
              onChange={handleChange(index)}
              expanded={expandedIndex === index}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" className={classes.summary}>
                  {faq.summary}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.details}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        <ul>
          {FAQs(classes).map((faq, index) => (
            <li className={classes.faqItem}>
              <div>
                <Typography variant="h6" className={classes.summary}>
                  {faq.summary}
                </Typography>
              </div>
              <div>
                <Typography>{faq.details}</Typography>
              </div>
            </li>
          ))}
        </ul>
        <div>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.moreInfoTitle}
          >
            More information
          </Typography>
        </div>
        <div className={classes.learnMore}>
          <Typography
            variant="h7"
            gutterBottom
            className={`${classes.font16} ${classes.italic}`}
          >
            Links provided here require you to be logged in.
          </Typography>
        </div>
        <div className={classes.learnMore}>
          <Typography variant="h7" gutterBottom className={classes.font16}>
            You can{' '}
            <Link href="https://neamig.sharepoint.com/sites/ithelp/SitePages/Engaging-with-IT.aspx">
              Contact Us
            </Link>{' '}
            during our operating hours if you are having trouble signing in.
          </Typography>
        </div>
        <div className={classes.learnMore}>
          <Typography variant="h7" gutterBottom className={classes.font16}>
            Managing your account is subject to our policies and procedures on
            how we control access to our systems, how we manage passwords and
            the way we prove you are who you say you are. Learn more about our
            policies and procedures:
          </Typography>
        </div>
        {PolicyLinks.map(link => (
          <div>
            <img
              src={PDFIcon}
              alt="pdf"
              style={{ width: '1.25rem' }}
              className={classes.pdfIcon}
            />
            <Link href={link.link} target="blank" className={classes.font16}>
              {link.title}
            </Link>
          </div>
        ))}
        <div className={classes.footer}></div>
      </Container>
    </Container>
  )
}

export default Landing
